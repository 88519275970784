<template>
  <v-dialog
    :value="true"
    @click:outside="close"
    max-width="800"
    scrollable
    :persistent="processing"
  >
    <v-card>
      <v-card-title>
        {{ $t('createNewCoupon') }}
      </v-card-title>
      <v-card-text class="pt-5">
        <CouponForm
          v-model="coupon"
          :parent-is-processing="processing"
          :parent-processing-error="missingRequiredInput"
          @setCouponItem="setCouponItem"
          @setValidLocations="setCouponValidLocations"
          v-on="$listeners"
          @childIsProcessing="childIsProcessing"
        />
      </v-card-text>
      <v-card-actions>
        <v-row
          dense
          justify="space-between"
        >
          <v-col
            cols="12"
            md="auto"
          >
            <v-btn
              @click="close"
              color="error"
              large
              block
              text
              :disabled="processing"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="auto"
          >
            <v-btn
              :disabled="processing"
              :loading="processing"
              large
              color="primary"
              block
              @click="create"
            >
              {{ $t('create') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CouponForm from "@/components/CouponForm"

export default {
	name: "CouponDialog",
	components: {
		CouponForm
	},
	data() {
		return {
			processing: false,
			missingRequiredInput: false,
			coupon: {
				code: null,
				label: null,
				description: null,
				maxRedemptions: 0,
				percentOff: 0,
				effectiveStartingAtTimestampInMillis: null,
				effectiveEndingAtTimestampInMillis: null,
				validWeekdays: null,
				item: null,
				customer: null,
				couponItem: null
			}
		}
	},
	watch: {
	},
	methods: {
		childIsProcessing(value) {
			this.processing = value
		},
		setCouponItem(value) {
			this.coupon.couponItem = value
		},
    setCouponValidLocations(value) {
      this.coupon.validLocations = value
    },
		close() {
			if(this.processing) {
				return
			}
			if(this.missingRequiredInput) {
				this.missingRequiredInput = false
			}
			for(let i in this.coupon) {
				this.coupon[i] = null
			}
			this.$emit('closed')
		},
		create: function () {
			this.missingRequiredInput = false
			if (!this.coupon.code || !this.coupon.label) {
				this.missingRequiredInput = true
				return
			}
			this.processing = true
			this.$store.dispatch('createCoupon', this.coupon).then(result => {
				if(result) {
					this.$emit('couponCreated', { success: true, coupon: result })
				}
			}).catch(error => {
				if(error) {
					this.$emit('couponCreated', { success: false, coupon: null })
				}
			}).finally(() => {
				this.processing = false
			})
		}
	}
}
</script>

<style scoped>

</style>
